!(function (e) {
    "use strict";
    function t(e, t, a) {
        var i;
        return function () {
            var n = this,
                o = arguments,
                r = function () {
                    (i = null), a || e.apply(n, o);
                },
                s = a && !i;
            clearTimeout(i), (i = setTimeout(r, t)), s && e.apply(n, o);
        };
    }
    function a(e) {
        var t = ++h;
        return String(null == e ? "rmjs-" : e) + t;
    }
    function i(e) {
        var t = e
                .clone()
                .css({
                    height: "auto",
                    width: e.width(),
                    maxHeight: "none",
                    overflow: "hidden",
                })
                .insertAfter(e),
            a = t.outerHeight(),
            i = parseInt(
                t
                    .css({ maxHeight: "" })
                    .css("max-height")
                    .replace(/[^-\d\.]/g, ""),
                10
            ),
            n = e.data("defaultHeight");
        t.remove();
        var o = i || e.data("collapsedHeight") || n;
        e.data({ expandedHeight: a, maxHeight: i, collapsedHeight: o }).css({
            maxHeight: "none",
        });
    }
    function n(e) {
        if (!d[e.selector]) {
            var t = " ";
            e.embedCSS &&
                "" !== e.blockCSS &&
                (t +=
                    e.selector +
                    " + [data-readmore-toggle], " +
                    e.selector +
                    "[data-readmore]{" +
                    e.blockCSS +
                    "}"),
                (t +=
                    e.selector +
                    "[data-readmore]{transition: height " +
                    e.speed +
                    "ms;overflow: hidden;}"),
                (function (e, t) {
                    var a = e.createElement("style");
                    (a.type = "text/css"),
                        a.styleSheet
                            ? (a.styleSheet.cssText = t)
                            : a.appendChild(e.createTextNode(t)),
                        e.getElementsByTagName("head")[0].appendChild(a);
                })(document, t),
                (d[e.selector] = !0);
        }
    }
    function o(t, a) {
        (this.element = t),
            (this.options = e.extend({}, s, a)),
            n(this.options),
            (this._defaults = s),
            (this._name = r),
            this.init(),
            window.addEventListener
                ? (window.addEventListener("load", l),
                  window.addEventListener("resize", l))
                : (window.attachEvent("load", l),
                  window.attachEvent("resize", l));
    }
    var r = "readmore",
        s = {
            speed: 100,
            collapsedHeight: 200,
            heightMargin: 16,
            moreLink: '<a href="#">Read More</a>',
            lessLink: '<a href="#">Close</a>',
            embedCSS: !0,
            blockCSS: "display: block; width: 100%;",
            startOpen: !1,
            beforeToggle: function () {},
            afterToggle: function () {},
        },
        d = {},
        h = 0,
        l = t(function () {
            e("[data-readmore]").each(function () {
                var t = e(this),
                    a = "true" === t.attr("aria-expanded");
                i(t),
                    t.css({
                        height: t.data(
                            a ? "expandedHeight" : "collapsedHeight"
                        ),
                    });
            });
        }, 100);
    (o.prototype = {
        init: function () {
            var t = this,
                n = e(this.element);
            n.data({
                defaultHeight: this.options.collapsedHeight,
                heightMargin: this.options.heightMargin,
            }),
                i(n);
            var o = n.data("collapsedHeight"),
                r = n.data("heightMargin");
            if (n.outerHeight(!0) <= o + r) return !0;
            var s = n.attr("id") || a(),
                d = t.options.startOpen
                    ? t.options.lessLink
                    : t.options.moreLink;
            n.attr({ "data-readmore": "", "aria-expanded": !1, id: s }),
                n.after(
                    e(d)
                        .on("click", function (e) {
                            t.toggle(this, n[0], e);
                        })
                        .attr({
                            "data-readmore-toggle": "",
                            "aria-controls": s,
                        })
                ),
                t.options.startOpen || n.css({ height: o });
        },
        toggle: function (t, a, i) {
            i && i.preventDefault(),
                t || (t = e('[aria-controls="' + this.element.id + '"]')[0]),
                a || (a = this.element);
            var n = this,
                o = e(a),
                r = "",
                s = "",
                d = !1,
                h = o.data("collapsedHeight");
            o.height() <= h
                ? ((r = o.data("expandedHeight") + "px"),
                  (s = "lessLink"),
                  (d = !0))
                : ((r = h), (s = "moreLink")),
                n.options.beforeToggle(t, a, !d),
                o.css({ height: r }),
                o.on("transitionend", function () {
                    n.options.afterToggle(t, a, d),
                        e(this)
                            .attr({ "aria-expanded": d })
                            .off("transitionend");
                }),
                e(t).replaceWith(
                    e(n.options[s])
                        .on("click", function (e) {
                            n.toggle(this, a, e);
                        })
                        .attr({
                            "data-readmore-toggle": "",
                            "aria-controls": o.attr("id"),
                        })
                );
        },
        destroy: function () {
            e(this.element).each(function () {
                var t = e(this);
                t
                    .attr({ "data-readmore": null, "aria-expanded": null })
                    .css({ maxHeight: "", height: "" })
                    .next("[data-readmore-toggle]")
                    .remove(),
                    t.removeData();
            });
        },
    }),
        (e.fn.readmore = function (t) {
            var a = arguments,
                i = this.selector;
            return (
                (t = t || {}),
                "object" == typeof t
                    ? this.each(function () {
                          if (e.data(this, "plugin_" + r)) {
                              var a = e.data(this, "plugin_" + r);
                              a.destroy.apply(a);
                          }
                          (t.selector = i),
                              e.data(this, "plugin_" + r, new o(this, t));
                      })
                    : "string" == typeof t && "_" !== t[0] && "init" !== t
                      ? this.each(function () {
                            var i = e.data(this, "plugin_" + r);
                            i instanceof o &&
                                "function" == typeof i[t] &&
                                i[t].apply(i, Array.prototype.slice.call(a, 1));
                        })
                      : void 0
            );
        });
})(jQuery);
